import { atom } from "recoil"

export const firstLoad = new Date()

export const firstLoadState = atom<Date>({
    key: "first_load",
    default: firstLoad,
})

export const lastLoadState = atom<Date>({
    key: "last_load",
    default: firstLoad,
})

export const isFirstLoadState = atom<boolean>({
    key: "is_first_load",
    default: true,
})
