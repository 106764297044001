import clsx from "clsx"
import React from "react"
import { FaTimes } from "react-icons/fa"
import { useRecoilValue } from "recoil"

import {
    Toast as ToastState,
    ToastType,
    toastsState,
    useCloseToast,
} from "@state/toasts"

function toastColor(type: ToastType) {
    switch (type) {
        case ToastType.SUCCESS:
            return "green"
        case ToastType.INFO:
            return "blue"
        case ToastType.WARNING:
            return "orange"
        case ToastType.ERROR:
            return "red"
        default:
            return "slate"
    }
}

const Toast = ({ toast }: { toast: ToastState }) => {
    const closeToast = useCloseToast(toast)

    const toastClass = clsx(
        "px-4 py-2 rounded shadow text-sm font-medium flex items-center gap-3 pointer-events-auto",
        `bg-${toastColor(toast.type)}-200`,
        `text-${toastColor(toast.type)}-900`,
        `border border-${toastColor(toast.type)}-300`,
    )

    return (
        <div className={toastClass}>
            <p className="flex-grow">{toast.message}</p>
            <a onClick={closeToast} className="-m-3 cursor-pointer p-3" title="Close">
                <FaTimes />
            </a>
        </div>
    )
}

const Toasts = () => {
    const toasts = useRecoilValue<ToastState[]>(toastsState)

    return (
        <div className="pointer-events-none absolute left-0 right-0 top-0 z-30 flex justify-center">
            <div className="w-full max-w-md space-y-3 pt-4">
                {toasts.map((toast) => (
                    <Toast key={toast.id} toast={toast} />
                ))}
            </div>
        </div>
    )
}

export default Toasts
