import { Role, User } from "@lib/api/models"
import { getOrganization } from "@state/organizations"

interface PermStringObj {
    [key: string]: any
}

function getUserPermissions(user: User, attr: string | null = null) {
    if (user === undefined) {
        return []
    }

    const organizationUser = user.organization_user.find(
        (orgUser) => orgUser.organization.slug === getOrganization().slug,
    )
    const userOrganizationPerms = []

    if (organizationUser) {
        for (const userAcctPerm of organizationUser.user_organization_permission) {
            if (attr !== null) {
                const stringObj = userAcctPerm.permission as PermStringObj
                userOrganizationPerms.push(stringObj[attr])
            } else {
                userOrganizationPerms.push(userAcctPerm)
            }
        }
    }

    return userOrganizationPerms
}

function getUserRole(user: User, roles: Role[]) {
    if (user === undefined || roles === undefined) {
        return ""
    }

    // Create map of permissions to roles via slug
    // {"Role Name": ["slug1", "slug2", ...]}
    const roleMap: PermStringObj = {}

    for (const role of roles) {
        roleMap[role.name] = []
        for (const rolePerm of role.organization_role_permissions) {
            roleMap[role.name].push(rolePerm.permission.slug)
        }
    }

    // Create array of user permissions
    const userOrganizationPerms = []
    for (const userAcctPerm of user.organization_user[0].user_organization_permission) {
        userOrganizationPerms.push(userAcctPerm.permission.slug)
    }

    // Check to see if the user has each role
    for (const role in roleMap) {
        if (
            JSON.stringify(roleMap[role].sort()) ===
            JSON.stringify(userOrganizationPerms.sort())
        ) {
            return role
        }
    }
    return "Custom"
}

function hasPermission(user: User, slug: string) {
    return getUserPermissions(user, "slug").includes(slug)
}

export default {
    getUserRole,
    getUserPermissions,
    hasPermission,
}
