import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import Script from "next/script"
import { ReCaptchaProvider } from "next-recaptcha-v3"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import React, { useEffect } from "react"
import { MutableSnapshot, RecoilRoot } from "recoil"

import Toasts from "@components/toasts"
import {
    ENVIRONMENT,
    GOOGLE_ANALYTICS,
    GOOGLE_ANALYTICS_ID,
    POSTHOG_ID,
    RECAPTCHA_SITE_KEY,
} from "@lib/constants"
import { isAuthReadyState } from "@state/auth"
import "../styles/globals.css"

// Check that PostHog is client-side (used to handle Next.js SSR)
// Only runs in Production environment when POSTHOG_ID is set
if (typeof window !== "undefined" && ENVIRONMENT === "production" && !!POSTHOG_ID) {
    posthog.init(POSTHOG_ID, {
        api_host: "https://app.posthog.com",
    })
}

function App({ Component, pageProps }: AppProps) {
    const router = useRouter()

    function initializeState({ set }: MutableSnapshot) {
        if (global.window) {
            set(isAuthReadyState, true)
        }
    }

    // Set up PostHog Tracking
    useEffect(() => {
        const handleRouteChange = () => posthog.capture("$pageview")
        router.events.on("routeChangeComplete", handleRouteChange)

        return () => router.events.off("routeChangeComplete", handleRouteChange)
    }, [])

    // Hide reCaptcha badge on non-auth pages
    useEffect(() => {
        const interval = setInterval(() => {
            const badge = document.querySelector(".grecaptcha-badge") as HTMLElement
            if (badge) {
                if (router.pathname.startsWith("/auth"))
                    badge.style.visibility = "visible"
                else badge.style.visibility = "hidden"
            }
        }, 100)

        return () => clearInterval(interval)
    }, [router.pathname])

    return (
        <RecoilRoot initializeState={initializeState}>
            {/* <!-- Google tag (gtag.js) --> */}
            {GOOGLE_ANALYTICS && (
                <>
                    <Script
                        id="google_tag"
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
                    ></Script>
                    <Script id="google_analytics">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${GOOGLE_ANALYTICS_ID}')
                        `}
                    </Script>
                </>
            )}

            {/* <!-- ActiveCampaign script --> */}
            <Script id="activecampaign">
                {`
                    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
                    vgo('setAccount', '1002780508');
                    vgo('setTrackByDefault', true);

                    vgo('process');
                `}
            </Script>

            <ReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} useEnterprise={true}>
                <PostHogProvider client={posthog}>
                    <Component {...pageProps} />
                </PostHogProvider>
            </ReCaptchaProvider>

            <Toasts />
        </RecoilRoot>
    )
}

export default App
