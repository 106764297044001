export class DataError extends Error {
    data: object

    constructor(message: string, data: object) {
        super(message)
        this.data = data
    }
}

export async function get(pathname: string, query?: object) {
    let url = "/api" + pathname
    if (query) url += "?" + new URLSearchParams({ ...query })
    console.log(`[GET ${url}]`)

    const options: RequestInit = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        } as HeadersInit,
    }
    const res = await fetch(url, options)

    if (res.status !== 200) {
        const body = await res.json()
        throw new DataError("Failed to GET", body.detail)
    } else {
        return await res.json()
    }
}

export async function post(pathname: string, data?: object) {
    const url = "/api" + pathname
    console.log(`[POST ${url}]`)

    const options: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        } as HeadersInit,
    }

    if (data) options.body = JSON.stringify(data)
    const res = await fetch(url, options)

    if (res.status !== 200) {
        const body = await res.json()
        throw new DataError("Failed to POST", body.detail)
    } else {
        return await res.json()
    }
}

export async function put(pathname: string, data?: object) {
    const url = "/api" + pathname
    console.log(`[PUT ${url}]`)

    const options: RequestInit = {
        method: "PUT",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        } as HeadersInit,
    }

    if (data) options.body = JSON.stringify(data)
    const res = await fetch(url, options)

    if (res.status !== 200) {
        const body = await res.json()
        throw new DataError("Failed to PUT", body.detail)
    } else {
        return await res.json()
    }
}

export async function _delete(pathname: string) {
    const url = "/api" + pathname
    console.log(`[DELETE ${url}]`)

    const options: RequestInit = {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        } as HeadersInit,
    }

    const res = await fetch(url, options)

    if (res.status !== 200) {
        const body = await res.json()
        throw new DataError("Failed to DELETE", body.detail)
    } else {
        return await res.json()
    }
}

export default {
    get,
    post,
    put,
    delete: _delete,
}
