import { atom, selectorFamily } from "recoil"

import { firstLoad } from "@state/global"
import type { User } from "@lib/api/models"
import api from "@lib/api/routes"

export const isAuthReadyState = atom<boolean>({
    key: "is_auth_ready",
    default: false,
})

export const waitForAuthReadyState = selectorFamily<boolean, Date>({
    key: "wait_for_auth_ready",
    get:
        () =>
        async ({ get }) => {
            const isAuthReady = get(isAuthReadyState)
            if (isAuthReady) return true
            else return new Promise<boolean>(() => undefined)
        },
})

export const lastAuthState = atom<Date>({
    key: "last_auth",
    default: firstLoad,
})

export const loadUserState = selectorFamily<User, Date>({
    key: "load_user",
    get:
        (refresh) =>
        async ({ get }) => {
            get(waitForAuthReadyState(refresh))
            return await api.check()
        },
})
