import { _delete, get, post, put } from "@lib/api/methods"
import {
    Automation,
    AutomationRun,
    AutomationRunStep,
    AutomationVersion,
    BillingResponse,
    Checklist,
    CreateChecklistInput,
    CreateInstanceBackupInput,
    CreateInstanceInput,
    CreateUserInput,
    DemoUsageLimit,
    Environment,
    EventsPaginated,
    ForgotInput,
    Instance,
    InstanceBackup,
    Landscape,
    LandscapeUrl,
    ListInstancesResponse,
    LoginInput,
    LoginResponse,
    Notification,
    NotificationsPaginated,
    Owner,
    Package,
    ResetInput,
    SignupResponse,
    UpdateChecklistInput,
    UpdateDeveloperPlanOwnerInput,
    UpdateInstanceInput,
    UpdateNotificationInput,
    User,
    UsersPaginated,
} from "@lib/api/models"

// Auth

export async function login(login_input: LoginInput): Promise<LoginResponse> {
    return await post("/auth/login", login_input)
}

export async function signup(
    create_user_input: CreateUserInput,
): Promise<SignupResponse> {
    return await post("/auth/signup", create_user_input)
}

export async function check(): Promise<User> {
    return await get("/auth/check")
}

export async function forgot(forgot_input: ForgotInput) {
    return await post("/auth/forgot", forgot_input)
}

export async function reset(reset_input: ResetInput) {
    return await post("/auth/reset", reset_input)
}

// License agreements

export async function agreeLicenseAgreement(
    organizationSlug: string,
    package_id: number,
) {
    return await post(
        `/organizations/${organizationSlug}/packages/${package_id}/license-agreement/agree`,
    )
}

// Permissions

export async function getRoles(organizationSlug: string) {
    return await get(`/organizations/${organizationSlug}/roles`)
}

export async function createUserRole(
    organizationSlug: string,
    userId: number,
    roleSlug: string,
) {
    return await post(
        `/organizations/${organizationSlug}/users/${userId}/roles/${roleSlug}`,
    )
}

export async function deleteUserRole(
    organizationSlug: string,
    userId: number,
    roleSlug: string,
) {
    return await _delete(
        `/organizations/${organizationSlug}/users/${userId}/roles/${roleSlug}`,
    )
}

export async function createUserPermission(
    organizationSlug: string,
    userId: number,
    permissionSlug: string,
) {
    return await post(
        `/organizations/${organizationSlug}/users/${userId}/permissions/${permissionSlug}`,
    )
}

export async function deleteUserPermission(
    organizationSlug: string,
    userId: number,
    permissionSlug: string,
) {
    return await _delete(
        `/organizations/${organizationSlug}/users/${userId}/permissions/${permissionSlug}`,
    )
}

// Instances

export async function listInstances(
    organizationSlug: string,
    name?: string,
): Promise<ListInstancesResponse> {
    const query = name ? { name } : undefined
    return await get(`/organizations/${organizationSlug}/instances`, query)
}

export async function getInstance(
    organizationSlug: string,
    id: number,
): Promise<Instance> {
    return await get(`/organizations/${organizationSlug}/instances/${id}`)
}

export async function deleteInstance(
    organizationSlug: string,
    id: number,
): Promise<Instance> {
    return await _delete(`/organizations/${organizationSlug}/instances/${id}`)
}

export async function updateInstance(
    organizationSlug: string,
    update_instance_id: number,
    update_instance_input: UpdateInstanceInput,
): Promise<Instance> {
    return await put(
        `/organizations/${organizationSlug}/instances/${update_instance_id}`,
        update_instance_input,
    )
}

export async function pauseInstance(
    organizationSlug: string,
    id: number,
): Promise<Instance> {
    return await post(`/organizations/${organizationSlug}/instances/${id}/pause`)
}

export async function resumeInstance(
    organizationSlug: string,
    id: number,
): Promise<Instance> {
    return await post(`/organizations/${organizationSlug}/instances/${id}/resume`)
}

export async function createInstance(
    organizationSlug: string,
    create_instance_input: CreateInstanceInput,
): Promise<Instance> {
    return await post(
        `/organizations/${organizationSlug}/instances`,
        create_instance_input,
    )
}

export async function restartSap(
    organizationSlug: string,
    instance: Instance,
): Promise<Instance> {
    return await put(
        `/organizations/${organizationSlug}/instances/${instance.id}/restart-sap`,
    )
}

export async function stopSap(
    organizationSlug: string,
    instance: Instance,
): Promise<Instance> {
    return await put(
        `/organizations/${organizationSlug}/instances/${instance.id}/stop-sap`,
    )
}

// InstanceBackups

export async function listBackups(organizationSlug: string): Promise<InstanceBackup[]> {
    return await get(`/organizations/${organizationSlug}/backups`)
}

export async function createBackup(
    organizationSlug: string,
    createBackupInput: CreateInstanceBackupInput,
): Promise<Instance> {
    return await post(`/organizations/${organizationSlug}/backups`, createBackupInput)
}

export async function deleteBackup(
    organizationSlug: string,
    backupId: number,
): Promise<Instance> {
    return await _delete(`/organizations/${organizationSlug}/backups/${backupId}`)
}

// Packages

export async function listPackages(
    organizationSlug: string,
    type?: string,
): Promise<Package[]> {
    const query = type ? { type } : undefined
    return await get(`/organizations/${organizationSlug}/packages`, query)
}

export async function getPackage(
    organizationSlug: string,
    id: number,
): Promise<Package> {
    return await get(`/organizations/${organizationSlug}/packages/${id}`)
}

// Events

interface ListEventsQuery {
    page?: number
    page_size?: number
    type?: string
    before_date?: string
    after_date?: string
}

export async function listEvents(
    organizationSlug: string,
    query: ListEventsQuery = {},
): Promise<EventsPaginated> {
    return await get(`/organizations/${organizationSlug}/events`, query)
}

// Landscape

export async function getLandscapeUrl(): Promise<LandscapeUrl> {
    return await get("/landscape/url")
}

// Admin

export async function createUser(
    organizationSlug: string,
    user: CreateUserInput,
): Promise<SignupResponse> {
    return await post(`/organizations/${organizationSlug}/users`, user)
}

export async function listUsers(organizationSlug: string): Promise<UsersPaginated> {
    return await get(`/organizations/${organizationSlug}/users`)
}

export async function deleteUser(
    organizationSlug: string,
    userId: number,
): Promise<void> {
    return await _delete(`/organizations/${organizationSlug}/users/${userId}`)
}

export async function updateDeveloperPlanOwner(
    organizationSlug: string,
    userId: number,
    update_owner_input: UpdateDeveloperPlanOwnerInput,
): Promise<Owner> {
    return await put(
        `/organizations/${organizationSlug}/users/${userId}/developer-plan`,
        update_owner_input,
    )
}

// Demo

export async function getDemoUsageLimit(
    organizationSlug: string,
): Promise<DemoUsageLimit> {
    return await get(`/organizations/${organizationSlug}/demo-usage-limit`)
}

// Checklist

export async function listChecklists(organizationSlug: string): Promise<Checklist[]> {
    return await get(`/organizations/${organizationSlug}/system-template-checklists`)
}

export async function createChecklist(
    organizationSlug: string,
    create_checklist_input: CreateChecklistInput,
): Promise<Checklist> {
    return await post(
        `/organizations/${organizationSlug}/system-template-checklists`,
        create_checklist_input,
    )
}

export async function updateChecklist(
    organizationSlug: string,
    checklist_id: number,
    update_checklist_input: UpdateChecklistInput,
): Promise<Checklist> {
    return await put(
        `/organizations/${organizationSlug}/system-template-checklists/${checklist_id}`,
        update_checklist_input,
    )
}

// Billing

export async function getBilling(
    organizationSlug: string,
    month: string,
): Promise<BillingResponse> {
    return await get(`/organizations/${organizationSlug}/billing/${month}`)
}

// Landscapes

export async function listLandscapes(organizationSlug: string): Promise<Landscape[]> {
    return await get(`/organizations/${organizationSlug}/landscapes`)
}

export async function getLandscape(
    organizationSlug: string,
    landscapeSlug: string,
): Promise<Landscape> {
    return await get(`/organizations/${organizationSlug}/landscapes/${landscapeSlug}`)
}

export async function getEnvironment(
    organizationSlug: string,
    landscapeSlug: string,
    environmentSlug: string,
): Promise<Environment> {
    return await get(
        `/organizations/${organizationSlug}/landscapes/${landscapeSlug}/environments/${environmentSlug}`,
    )
}

// Automations
// TODO: These are all missing an environment context

export async function listAutomations(
    organizationSlug: string,
    landscapeSlug: string,
    environmentSlug: string,
): Promise<Automation[]> {
    return await get(
        `/organizations/${organizationSlug}/landscapes/${landscapeSlug}/environments/${environmentSlug}/automations`,
    )
}

export async function getAutomation(
    organizationSlug: string,
    automationId: number,
): Promise<Automation> {
    return await get(`/organizations/${organizationSlug}/automations/${automationId}`)
}

export async function listAutomationVersions(
    organizationSlug: string,
    automationId: number,
): Promise<AutomationVersion[]> {
    return await get(
        `/organizations/${organizationSlug}/automations/${automationId}/versions`,
    )
}

export async function getAutomationVersion(
    organizationSlug: string,
    automationId: number,
    automationVersionId: number,
): Promise<AutomationVersion> {
    return await get(
        `/organizations/${organizationSlug}/automations/${automationId}/versions/${automationVersionId}`,
    )
}

export async function listAutomationRuns(
    organizationSlug: string,
    landscapeSlug: string,
    environmentSlug: string,
    automationId?: number,
    status?: string,
): Promise<AutomationRun[]> {
    let url = `/organizations/${organizationSlug}/landscapes/${landscapeSlug}/environments/${environmentSlug}/automations/runs`
    const query = new URLSearchParams()
    if (automationId) query.set("automation_id", automationId.toString())
    if (status) query.set("status", status.toString())
    if (query.toString().length) url += `?${query.toString()}`
    return await get(url)
}

export async function getAutomationRun(
    organizationSlug: string,
    automationId: number,
    automationVersionId: number,
    automationRunId: number,
): Promise<AutomationRun> {
    return await get(
        `/organizations/${organizationSlug}/automations/${automationId}/versions/${automationVersionId}/runs/${automationRunId}`,
    )
}

export async function createAutomationRun(
    organizationSlug: string,
    automationId: number,
    automationVersionId: number,
): Promise<AutomationRun> {
    return await post(
        `/organizations/${organizationSlug}/automations/${automationId}/versions/${automationVersionId}/runs`,
    )
}

// TODO: Should this return an AutomationRun?
export async function updateAutomationRunStep(
    organizationSlug: string,
    automationId: number,
    automationVersionId: number,
    automationRunId: number,
    stepId: number,
    update: { status: string; params?: any },
): Promise<AutomationRunStep> {
    return await put(
        `/organizations/${organizationSlug}/automations/${automationId}/versions/${automationVersionId}/runs/${automationRunId}/steps/${stepId}`,
        update,
    )
}

// Notifications

interface ListNotificationsQuery {
    is_read?: boolean
    page?: number
    page_size?: number
}

export async function listNotifications(
    organizationSlug: string,
    query: ListNotificationsQuery = {},
): Promise<NotificationsPaginated> {
    return await get(`/organizations/${organizationSlug}/notifications`, query)
}

export async function updateNotification(
    organizationSlug: string,
    notification_id: number,
    update_notification_input: UpdateNotificationInput,
): Promise<Notification> {
    return await put(
        `/organizations/${organizationSlug}/notifications/${notification_id}`,
        update_notification_input,
    )
}

// All together

export default {
    login,
    signup,
    check,
    forgot,
    reset,

    getRoles,
    createUserRole,
    deleteUserRole,
    createUserPermission,
    deleteUserPermission,

    agreeLicenseAgreement,

    listInstances,
    getInstance,
    deleteInstance,
    updateInstance,
    pauseInstance,
    resumeInstance,
    createInstance,
    restartSap,
    stopSap,

    listBackups,
    createBackup,
    deleteBackup,

    listPackages,
    getPackage,

    listEvents,

    getLandscapeUrl,

    createUser,
    listUsers,
    deleteUser,
    updateDeveloperPlanOwner,

    getDemoUsageLimit,

    listChecklists,
    createChecklist,
    updateChecklist,

    getBilling,

    listLandscapes,
    getLandscape,
    getEnvironment,

    listAutomations,
    getAutomation,
    listAutomationVersions,
    getAutomationVersion,
    listAutomationRuns,
    getAutomationRun,
    createAutomationRun,
    updateAutomationRunStep,

    listNotifications,
    updateNotification,
}
