import { Organization, OrganizationUser } from "@lib/api/models"

// Getters
// -------

// Check local storage for an OrganizationUser, and kick back to the login page if it's not there
export function getOrganizationUser(): OrganizationUser {
    const organizationUser = localStorage.getItem("organization_user")
    if (!organizationUser) {
        window.location.href = "/auth/login"
        throw new Error("No Organization in local storage")
    }
    return JSON.parse(organizationUser) as OrganizationUser
}

// Get the Organization from the current OrganizationUser
export function getOrganization(): Organization {
    return getOrganizationUser().organization
}

// Check local storage for a list of OrganizationUsers, and kick back to the login page if they're not set
export function getOrganizationUsers(): OrganizationUser[] {
    const organizations = localStorage.getItem("organization_users")
    if (!organizations) {
        window.location.href = "/auth/login"
        throw new Error("No OrganizationUsers in local storage")
    }
    return JSON.parse(organizations) as OrganizationUser[]
}

// Setters
// -------

// Set the OrganizationUser in local storage (called from login page)
export function setOrganizationUser(organizationUser: OrganizationUser) {
    localStorage.setItem("organization_user", JSON.stringify(organizationUser))
}

// Set all available Organizations in local storage (called from login page)
export function setOrganizationUsers(organizationUsers: OrganizationUser[]) {
    localStorage.setItem("organization_users", JSON.stringify(organizationUsers))
}
