import { ReactNode, isValidElement } from "react"
import { atom, selector, useRecoilCallback } from "recoil"

import { randomString } from "@lib/helpers"

const TOAST_DEFAULT_EXPIRATION = 4000

export enum ToastType {
    SUCCESS,
    INFO,
    WARNING,
    ERROR,
}

export type NewToast = {
    message: string | ReactNode
    type?: ToastType
    expiration?: number
}

export type Toast = {
    id: string
    message: string | ReactNode
    type: ToastType
    expiration: number
}

export const expiredToastIdsState = atom<string[]>({
    key: "expired_toast_ids",
    default: [],
})

export const allToastsState = atom<Toast[]>({
    key: "all_toasts",
    default: [],
})

export const toastsState = selector<Toast[]>({
    key: "toasts",
    get: ({ get }) => {
        const allToasts = get(allToastsState)
        const expiredToastIds = get(expiredToastIdsState)
        return allToasts.filter(({ id }) => {
            return !expiredToastIds.includes(id)
        })
    },
})

export function useAddToast() {
    const expireIt = useRecoilCallback(
        ({ snapshot, set }) =>
            async (expireId: string) => {
                const expired = await snapshot.getPromise(expiredToastIdsState)
                set(expiredToastIdsState, [...expired, expireId])
            },
    )

    const addToast = useRecoilCallback(
        ({ snapshot, set }) =>
            async (newToast: NewToast) => {
                const currentType = newToast.type ?? ToastType.INFO
                const currentMessageChildren = isValidElement(newToast?.message)
                    ? (newToast?.message as React.ReactElement).props?.children
                    : newToast?.message
                const currentToasts = await snapshot.getPromise(toastsState)
                const expiredToastIds = await snapshot.getPromise(expiredToastIdsState)
                const toast: Toast = {
                    id: randomString(8),
                    message: newToast.message,
                    type: currentType,
                    expiration: newToast.expiration ?? TOAST_DEFAULT_EXPIRATION,
                }

                for (const toast of currentToasts) {
                    // check is message contents are the same
                    if (toast.message && isValidElement(toast.message)) {
                        const toastMessageChildren = (
                            toast.message as React.ReactElement
                        ).props.children

                        let sameMessage =
                            toastMessageChildren.length ===
                            currentMessageChildren.length

                        for (let i = 0; i < toastMessageChildren.length; i++) {
                            if (
                                (typeof toastMessageChildren[i] === "string" &&
                                    toastMessageChildren[i] !==
                                        currentMessageChildren[i]) ||
                                (typeof toastMessageChildren[i] !== "string" &&
                                    toastMessageChildren[i].props.children !==
                                        currentMessageChildren[i].props.children)
                            ) {
                                sameMessage = false
                                break
                            }
                        }

                        if (
                            toast.type === currentType &&
                            sameMessage &&
                            !expiredToastIds.includes(toast.id)
                        ) {
                            set(expiredToastIdsState, [...expiredToastIds, toast.id])
                        }
                    }
                }

                set(allToastsState, [...currentToasts, toast])
                setTimeout(expireIt.bind(null, toast.id), toast.expiration)
            },
    )

    return addToast
}

export function useCloseToast(toast: Toast) {
    const closeToast = useRecoilCallback(({ snapshot, set }) => async () => {
        const expiredToastIds = await snapshot.getPromise(expiredToastIdsState)
        set(expiredToastIdsState, [...expiredToastIds, toast.id])
    })
    return closeToast
}
